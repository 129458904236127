import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/images/logo.jpg";
import "./Footer.css"; 

import {
  MdOutlineMapsHomeWork,
  MdWifiCalling,
  MdOutlineMailOutline,
} from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer text-white mb-0 pb-0">
      <Container>
        <Row className="text-center text-md-left align-items-start sub-container">
          {/* Company Info */}
          <Col
            xs={12}
            md={3}
            className="company d-flex flex-column  align-items-md-start"
          >
            <img
              src={logo}
              alt="Company Logo"
              className="footer-logo rounded-circle mb-3"
            />
            <p className="company-description  text-md-left">
              KB Software Solutions for innovative, tailored technology
              solutions, expert development, and dedicated support to drive your
              business success.
              <a
              href="/about"
              className="text-decoration-none"
            >
              <h6 className="flex-column align-items-start">
                More about Company <FaArrowRightLong />
              </h6>
              </a>
            </p>
          </Col>

          {/* Useful Links */}
          <Col
            xs={12}
            md={3}
            className="useful-links d-flex flex-column align-items-start align-items-md-center"
          >
            <h2>Useful Links</h2>
            <ul className="list-unstyled ">
              <li>
                <a href="/" className="text-white">
                  {" "}
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-white">
                  {" "}
                  About Us
                </a>
              </li>
              <li>
                <a href="/development" className="text-white">
                  {" "}
                  Our Services
                </a>
              </li>
              <li>
              <a href="/team" className="text-white">
                {" "}
                Our Team
              </a>
            </li>
            <li>
            <a href="/project" className="text-white">
              {" "}
              Projects
            </a>
          </li>
              <li>
                <a href="/contact" className="text-white">
                  {" "}
                  Contact Us
                </a>
              </li>
            </ul>
          </Col>

          {/* Contact Us */}
          <Col xs={12} md={3} className="contact-us">
            <h2>Contact Us</h2>
            <p className="d-flex align-items-center ">
              <p className="mx-1 px-3">
                <MdOutlineMapsHomeWork
                  className="mr-2"
                  style={{ fontSize: "2em" }}
                />
              </p>
              <span>
                {" "}
                Office No. 1429, 14th Floor, Block D & E, CCC, VIP Road
                Zirakpur, 140603
              </span>
            </p>
            <p className="d-flex align-items-center">
              <p className="px-3">
                {" "}
                <MdWifiCalling className="mr-2" style={{ fontSize: "2em" }} />
              </p>

              <span>+91-83201-21716 Mon - Sat: 9:30 AM to 7:00 PM.</span>
            </p>
            <p className="d-flex align-items-center">
              <p className="email px-3">
                <MdOutlineMailOutline
                  className="mr-2"
                  style={{ fontSize: "2em" }}
                />
              </p>
              <span>kharidobefikar@gmail.com</span>
            </p>
          </Col>

          {/* Map */}
          <Col xs={12} md={3} className="map">
            <h2>Map</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d109795.43594645066!2d76.72955229957611!3d30.687254906887183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sKharido%20befikar%2C%20320%20%2C%20block%20b%2C%20Chandigarh%20Citi%20Center%20Office%2C%20VIP%20Rd%2C%20Zirakpur%2C%20Punjab%20140603!5e0!3m2!1sen!2sin!4v1730797733943!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Container>

      {/* Footer Bottom */}
      <div className="footer-bottom text-center py-3 mt-4">
        <h1 className="footer-bottom-text">
          2024 @ KB Software Solutions All Rights Reserved
        </h1>
      </div>
    </div>
  );
};

export default Footer;
