import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ServiceHeader.css";

function ServiceDropdown({ show, onHide }) {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="custom-service-dropdown"
      centered
      backdropClassName="custom-backdrop"
    >
      <Modal.Body className="service-dropdown-body">
        <div className="service-dropdown-content">
          <div className="service-column">
            <h5 onClick={() => handleItemClick("/development")}>Development</h5>
            <ul>
              <li onClick={() => handleItemClick("/development")}>
                Web Development
              </li>
              <li onClick={() => handleItemClick("/development")}>
                Android Development
              </li>
              <li onClick={() => handleItemClick("/development")}>
                E-Commerce Website
              </li>
              <li onClick={() => handleItemClick("/development")}>
                Dynamic Website
              </li>
              <li onClick={() => handleItemClick("/development")}>
                Static Website
              </li>
              <li onClick={() => handleItemClick("/development")}>
                CRM/HRM Website
              </li>
            </ul>
          </div>
          <div className="service-column">
            <h5 onClick={() => handleItemClick("/digital-marketing")}>
              Digital Marketing
            </h5>
            <ul>
              <li onClick={() => handleItemClick("/digital-marketing")}>SEO</li>
              <li onClick={() => handleItemClick("/digital-marketing")}>
                Social Media Management
              </li>
              <li onClick={() => handleItemClick("/digital-marketing")}>
                Local SEO
              </li>
              <li onClick={() => handleItemClick("/digital-marketing")}>
                Marketing Campaigns
              </li>
              <li onClick={() => handleItemClick("/digital-marketing")}>
                PPC Management
              </li>
            </ul>
          </div>
          <div className="service-column">
            <h5 onClick={() => handleItemClick("/designing")}>Designing</h5>
            <ul>
              <li onClick={() => handleItemClick("/designing")}>
                Graphic Designing
              </li>
              <li onClick={() => handleItemClick("/designing")}>
                Web Designing
              </li>
              <li onClick={() => handleItemClick("/designing")}>
                Logo Designing
              </li>
              <li onClick={() => handleItemClick("/designing")}>
                Figma Designing
              </li>
              <li onClick={() => handleItemClick("/designing")}>
                Website Redesigning
              </li>
              <li onClick={() => handleItemClick("/designing")}>
                Enhance Web Designing
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ServiceDropdown;
